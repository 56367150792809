<template>
  <div>

    <div class="container">
      <div class="row ">
        <div class="col-4 align-content-center justify-content-center">
          <h1>ChatGPT Service</h1>
        </div>
      </div>
    </div>

    <div class="container">


      <div class="p-4">
        <form>
          <div class="row">
            <div class="col-2">
              <div class="form-group">
                <label>Weights</label>
                <div class="position-relative">
                  <div class="form-group mb-0">
                    <select class="form-control custom-select" v-model="input_api.model">
                      <option v-for="v in models" :value="v">{{ v }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">

            <div class="col-md-12">
              <div class="form-group">
                <label>Введите промпт</label>
                <div class="position-relative">
                  <book-icon class="fea icon-sm icons"></book-icon>
                  <textarea name="product" id="product" class="form-control pl-5" placeholder="Введите промпт :"
                    v-model="input_api.prompt" />
                </div>
              </div>
            </div>

          </div>
          <!--end row-->
          <div class="row mt-4">
            <div class="col-12">
              <a id="div-with-loading" class="btn btn-primary vs-con-loading__container" @click="askApi"> Send </a>
            </div>
            <!--end col-->
          </div>


          <!--end row-->
        </form>
        <!--end form-->
      </div>



    </div>


    <div class="row m-12 mb-5 justify-content-center align-items-center">


      <div class="col-11">
        <div class="table-responsive bg-white shadow rounded">
          <table class="table mb-0 table-center" style="width:100%;" v-if="tableShow">
            <thead>
              <tr>
                <th scope="col">Ответ:</th>

              </tr>
            </thead>
            <tbody>
              <tr>

                <td>{{ chatresponse.response }}</td>


              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>


    <div class="row m-12 mb-5 justify-content-center align-items-center">


      <div class="col-11">
        <div class="table-responsive bg-white shadow rounded">
          <table class="table mb-0 table-center" style="width:100%;">
            <thead>
              <tr>
                <th scope="col">История запросов:</th>

              </tr>
            </thead>
            <tbody>
              <tr v-for="(response, ix) in history">

                <td class="col-1">{{ ix + 1 }}.</td>
                <td class="col-2">{{ response.prompt }}</td>
                <td class="col-2">{{ response.model }}</td>
                <td class="col-7">{{ response.answer }}</td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
import store from "@/store/store";
import { MessageCircleIcon, BookIcon, DownloadIcon } from "vue-feather-icons";

let sseClient;


function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}


export default {
  components: {
    MessageCircleIcon, BookIcon, DownloadIcon,
  },
  name: "ChatGPT",
  data() {
    return {
      models: ['gpt-3.5-turbo', 'gpt-4', 'gpt-4-0314', 'gpt-3.5-turbo-0301'],

      chatresponse: {},
      history: [],
      input_api: {
        user_id: 0,
        prompt: '',
        model: 'gpt-3.5-turbo'
      },
      tableShow: false,
      showStatus: false,
      status: {
        rows_in: 0, rows_out: 0,
      },
      token: '',
      excelResults: [],
      refreshTime: 1,
    }
  },


  mounted() {


    sseClient = this.$sse.create({
      url: '' + this.$hostnamejava + '/chatgpt-sse/subscribe/' + store.state.user_id,
      format: 'json',
      withCredentials: false,
      polyfill: true,
    });

    sseClient.on('message', this.handleMessage);

    sseClient.connect()
      .then(sse => {
        console.log('We\'re connected!');

      })
      .catch((err) => {
        console.error('Failed to connect to server', err);
      });

    this.getHistory();
  },


  methods: {
    ask() {
      console.log('inside ask')
      this.input_api.user_id = store.state.user_id;
      console.log(this.input_api)
      this.$vs.loading({
        container: '#div-with-loading',
        scale: 0.5,
        opacity: 0.1,
      });
      this.axios({
        method: 'post',
        //url: '' +this.$hostnamejava +  '/chatgpt/ask',
        url: '' + this.$hostnamejava + '/chatgpt/ask',
        data: this.input_api,
      }).then((res) => {
        console.log(res.data);
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        this.chatresponse = res.data;
        this.tableShow = true;
      }).catch((err) => {
        console.error(err);
        //loading.close();
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        //this.$router.push('prospect-pool')
      });

    },
    askApi() {
      console.log('inside ask')
      this.input_api.user_id = store.state.user_id;
      console.log(this.input_api)
      this.$vs.loading({
        container: '#div-with-loading',
        scale: 0.5,
        opacity: 0.1,
      });
      this.axios({
        method: 'post',
        //url: '' +this.$hostnamejava +  '/chatgpt/ask',
        url: '' + this.$hostnamejava + '/chatgpt/ask-api',
        data: this.input_api,
      }).then((res) => {
        console.log(res.data);
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        this.chatresponse = res.data;
        this.tableShow = true;
      }).catch((err) => {
        console.error(err);
        //loading.close();
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        //this.$router.push('prospect-pool')
      });

    },
    getHistory() {
      console.log('inside history')
      this.input_api.user_id = store.state.user_id;
      this.axios({
        method: 'post',

        url: '' + this.$hostnamejava + '/chatgpt/get-history',
        data: this.input_api,
      }).then((res) => {
        this.history = res.data.result;

      }).catch((err) => {
        console.error(err);
      });

    },
    handleMessage(message, lastEventId) {
      this.chatresponse = message;
    },



  }
}
</script>

<style lang="scss"></style>